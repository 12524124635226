import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import { injectIntl } from "gatsby-plugin-intl";
import { Contentful_Page, Contentful_PageBlocksItem } from "graphql-types";
import { ThemeContext, ContentTypeColour } from "../contexts/ThemeContext";
import Layout from "../layouts/layout";
import BlockLoader, { hasHeroBlock } from "../components/block-loader";
import contentfulImageHelper from "../utils/contentful-image-helper";

const PageEntryTemplate = ({ data }: { data: any }) => {
  const pageData: Contentful_Page = get(data, "contentful.page");

  /**
   * As page entries contain dynamic content blocks, we need to determine whether
   * the page's first block is a hero. This affects the style of the navigation bar.
   */
  const firstBlock: Contentful_PageBlocksItem | undefined = get(pageData, "blocksCollection.items.0");
  const isPrimaryNavigationDark = pageData.isPrimaryNavigationDark ?? false;

  if (!pageData.blocksCollection) return null;

  return (
    <ThemeContext.Provider value={ContentTypeColour.Default}>
      <Layout
        headSnippet={pageData.headSnippet}
        title={pageData.title}
        metaDescription={pageData.metaDescription}
        metaImageUrl={contentfulImageHelper(pageData.shareImage).getShareUrl()}
        hasHero={hasHeroBlock(firstBlock) ? true : !isPrimaryNavigationDark}
      >
        <main>
          <h1 className="sr-only">{pageData.title}</h1>
          <BlockLoader
            blocks={pageData.blocksCollection.items}
            hasSideNavigation={pageData.hasSideNavigation ?? false}
            pageId={pageData.sys.id}
          />
        </main>
      </Layout>
    </ThemeContext.Provider>
  );
};

export default injectIntl(PageEntryTemplate);

export const pageQuery = graphql`
  query PageById($id: String!, $contentful_locale: String!, $preview: Boolean!) {
    contentful {
      page(id: $id, locale: $contentful_locale, preview: $preview) {
        ...pageFields
      }
    }
  }
`;
